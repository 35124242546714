import { useRecoilValue } from 'recoil';
import { currentDatabaseSelector, selectedLockYearState } from '@transport-insights/uikit';
import { useQuery } from 'react-query';
import {
  calculateColourValuesThree,
  convertAreaGraph,
  convertAreaGraph2, convertAreaGraph3,
  convertAreaLineGraph, convertAudit,
  convertDoubleAxisBarchart,
  convertDQAssetManagement, convertGDPData,
  convertGroupedBarchart,
  convertLineGraph,
  convertLineGraphFilter, convertServicePerformance,
  convertTornadoChart,
  returnSingleValue,
  calculateColourValues,
} from '@src/modules/performance/shared/reportCalculations';
import {
  calculateAverageYearRange,
  calculateCoinvestInvestmentKPI,
  calculateCoinvestTechnicalKPI, calculateDeliveryKPI,
  calculateNetworkCharacteristicsKPI,
  calculatePublicTransportKPI,
  calculateRoadNetworkUseKPI,
  calculateTerritorialActivityKPI,
} from '@src/modules/performance/shared/kpi_calcs';
import { convertToFinancialYear } from '@src/utils/dates';
import { isEmpty } from 'lodash';
import usePerformanceReportsApi from '../api/usePerformanceReportsApi';

// these reports are locked to this lockyear for now
// The dash is required for the backend
export const LOCKYEAR = 'latest';

export function useStateHighwayPotholeRepairsKPI() {
  const rca = useRecoilValue(currentDatabaseSelector);
  const api = usePerformanceReportsApi();

  return useQuery(
    ['state-highway-pothole-repairs-kpi', rca?.id],
    () => api.getKpi('state-highways-potholes', rca?.id),
    {
      enabled: rca?.id !== undefined,
    },
  );
}

export function useE3Kpi() {
  const rca = useRecoilValue(currentDatabaseSelector);
  const api = usePerformanceReportsApi();
  return useQuery(
    ['e3-kpi', rca?.id],
    async () => api.getKpi('e3', rca?.id),
    {
      enabled: rca?.id !== undefined,
    },
  );
}

export function useServicePerformanceKPI() {
  const rca = useRecoilValue(currentDatabaseSelector);
  const api = usePerformanceReportsApi();
  return useQuery(
    ['service-performance-kpi', rca?.id],
    async () => api.getServicePerformanceKPI(rca?.id, LOCKYEAR),
    {
      enabled: rca?.id !== undefined,
    },
  );
}

export function useAnnualReports() {
  const rca = useRecoilValue(currentDatabaseSelector);
  const api = usePerformanceReportsApi();
  return useQuery(
    ['annual-reports', rca?.id],
    async () => api.getAnnualReports(rca?.id),
    {
      enabled: rca?.id !== undefined,
    },
  );
}

export function useActivityManagementPlans() {
  const rca = useRecoilValue(currentDatabaseSelector);
  const api = usePerformanceReportsApi();
  return useQuery(
    ['activity-management-plans', rca?.id],
    async () => api.getActivityManagementPlans(rca?.id),
    {
      enabled: rca?.id !== undefined,
      // don't retry on unauthorized
      retry: (failureCount, error) => error?.response?.status !== 403,
    },
  );
}

export function useMiscActivityManagementPlans() {
  const rca = useRecoilValue(currentDatabaseSelector);
  const api = usePerformanceReportsApi();
  return useQuery(
    ['activity-management-plans-misc', rca?.id],
    async () => api.getMiscActivityManagementPlans(rca?.id),
    {
      enabled: rca?.id !== undefined,
      // don't retry on unauthorized
      retry: (failureCount, error) => error?.response?.status !== 403,
    },
  );
}

export function useReport(name, processor, framework = null) {
  const rca = useRecoilValue(currentDatabaseSelector);
  const api = usePerformanceReportsApi();
  return useQuery(
    [name, rca?.id, framework],
    async () => api.getReport(name, rca?.id, LOCKYEAR, framework),
    {
      enabled: rca?.id !== undefined,
      select: (data) => (processor ? processor(data) : data),
    },
  );
}

export function useReportWithLockYear(name, processor) {
  const rca = useRecoilValue(currentDatabaseSelector);
  const lockYear = useRecoilValue(selectedLockYearState);
  const api = usePerformanceReportsApi();
  const encodedLockYear = lockYear?.replace('/', '-') || null;
  return useQuery(
    [name, rca?.id, lockYear],
    async () => api.getReport(name, rca?.id, encodedLockYear),
    {
      enabled: rca?.id !== undefined && lockYear !== null,
      select: (data) => (processor ? processor(data) : data),
    },
  );
}

// Service Performance Hooks
export function useServicePerformance() {
  return useReport(
    'service-performance/service-performance',
    (res) => convertServicePerformance(res),
  );
}

// Network Characteristics Hooks
export function useNetworkCharacteristicsKPI() {
  return useReport(
    'network-characteristics/network-characteristics-kpi',
    (res) => ({ ...calculateNetworkCharacteristicsKPI(res), lockYear: res[0]?.LockYear || null, averageYearRange: calculateAverageYearRange(res[0]?.LockYear || null, 5) }),
  );
}

export function useRoadsNetworkLength() {
  return useReport(
    'network-characteristics/roads-network-length',
    (res) => ({ ...convertAreaGraph3(res, 'Type', ['Sealed', 'Unsealed'], 'Length'), lockYear: res[0]?.LockYear || null }),
  );
}

export function useRoadsUrbanLength() {
  return useReport(
    'network-characteristics/roads-urban-length',
    (res) => ({ ...convertLineGraph(res, ['Urban_pc'], null, [100]), lockYear: res[0]?.LockYear || null }),
  );
}

// Road Network Use Hooks
export function useRoadNetworkUseKPI() {
  return useReport(
    'road-network-use/road-network-use-kpi',
    (res) => ({ ...calculateRoadNetworkUseKPI(res), lockYear: res[0]?.LockYear || null, averageYearRange: calculateAverageYearRange(res[0]?.LockYear || null, 5) }),
  );
}

// Public Transport Hooks
export function usePublicTransportKPI() {
  return useReport(
    'public-transport/public-transport-kpi',
    (res) => ({ ...calculatePublicTransportKPI(res), lockYear: res[0]?.LockYear || null, averageYearRange: calculateAverageYearRange(res[0]?.LockYear || null, 5) }),
  );
}

export function usePassengerKms() {
  return useReport(
    'public-transport/passenger-kms',
    (res) => ({ ...convertLineGraph(res, ['PassengerKilometresKm'], null, null, true), lockYear: res[0]?.LockYear || null }),
  );
}

export function useNoBuses() {
  return useReport(
    'public-transport/no-buses',
    (res) => ({ ...convertLineGraph(res, ['FleetSizeCount'], null, null, true), lockYear: res[0]?.LockYear || null }),
  );
}

export function useServiceKms() {
  return useReport(
    'public-transport/service-kms',
    (res) => ({ ...convertLineGraph(res, ['ServiceKilometresKm'], null, null, true), lockYear: res[0]?.LockYear || null }),
  );
}

export function usePopulationServicedCommunity() {
  return useReport(
    'public-transport/population-serviced-community',
    (res) => ({ ...convertLineGraph(res, ['Population'], null, null, true), lockYear: res[0]?.LockYear || null }),
  );
}

export function usePopulationBusStop() {
  return useReport(
    'public-transport/population-busstop',
    (res) => ({ ...convertLineGraph(res, ['PercWithin500m'], null, null, true), lockYear: res[0]?.LockYear || null }),
  );
}

export function useBoardings() {
  return useReport(
    'public-transport/boardings',
    (res) => {
      if (res.length === 0) return null;
      const bus = res.filter((row) => row.PT_Mode === 'Bus');
      const rail = res.filter((row) => row.PT_Mode === 'Rail');
      const ferry = res.filter((row) => row.PT_Mode === 'Ferry');

      const line1 = convertLineGraph(bus, ['Value'], 1000000);
      const line2 = convertLineGraph(rail, ['Value'], 1000000);
      const line3 = convertLineGraph(ferry, ['Value'], 1000000);

      return {
        years: line1.years,
        values: [
          line1.values[0],
          line2.values[0],
          line3.values[0],
        ].filter((v) => !isEmpty(v)),
        lockYear: res[0]?.LockYear || null,
      };
    },
  );
}

export function useServiceExpenditure() {
  return useReport(
    'public-transport/service-expenditure',
    (res) => ({ ...convertLineGraph(res, ['Value', 'HighPercentile', 'LowPercentile'], 1000000), lockYear: res[0]?.LockYear || null }),
  );
}

export function useInfrastructureExpenditure() {
  return useReport(
    'public-transport/infrastructure-expenditure',
    (res) => ({ ...convertLineGraph(res, ['Value', 'HighPercentile', 'LowPercentile'], 1000000), lockYear: res[0]?.LockYear || null }),
  );
}

export function useServicedCommunities() {
  return useReport('public-transport/serviced-communities');
}

export function useTotalMobilityServicedCommunities() {
  return useReport('public-transport/total-mobility-communities');
}

export function useTotalMobilityUse() {
  return useReport(
    'public-transport/total-mobility-use',
    (res) => ({ ...convertDoubleAxisBarchart(res, ['PersonsRegistered', 'Boardings'], 'Year', null, null, true), lockYear: res[0]?.LockYear || null }),
  );
}

// Road Network Use Hooks
export function useVKT() {
  return useReport(
    'road-network-use/vkt',
    (res) => {
      const urban = res.filter((row) => row.RoadType === 'U' || row.RoadType === 'Urban');
      const rural = res.filter((row) => row.RoadType === 'R' || row.RoadType === 'Rural');

      const line1 = convertLineGraph(urban, ['VKT'], [1000000]);
      const line2 = convertLineGraph(rural, ['VKT'], [1000000]);

      return {
        years: line1.years,
        values: [
          line1.values[0],
          line2.values[0],
        ],
        lockYear: res[0]?.LockYear || null,
      };
    },
  );
}

export function useRestrictedBridges() {
  return useReport(
    'road-network-use/restricted-bridges',
    (res) => ({ ...convertLineGraph(res, ['SpeedOnlyCount', 'WeightCount']), lockYear: res[0]?.LockYear || null }),
  );
}

export function useLengthVsVKT(framework) {
  return useReport(
    'road-network-use/length-vs-vkt',
    (res) => ({ ...convertTornadoChart(res, ['PercTotalKm', 'PercTotalVKT'], 'Category'), lockYear: res[0]?.LockYear || null }),
    framework,
  );
}

// Network Characteristics Hooks
export function useCyclewaysNetworkLength() {
  return useReport(
    'network-characteristics/cycleways-network-length',
    (res) => ({ ...convertAreaGraph3(res, 'Type', ['Rural', 'Urban'], 'Length'), lockYear: res[0]?.LockYear || null }),
  );
}

export function useBridgesCount() {
  return useReport(
    'network-characteristics/bridges-count',
    (res) => ({ ...convertLineGraph(res, ['AllBridgesCount', 'TimberCount', 'SingleLaneCount']), lockYear: res[0]?.LockYear || null }),
  );
}

// Territorial Activity Hooks
export function useTerritorialActivityKPI() {
  return useReport(
    'territorial-activity/territorial-activity-kpi',
    (res) => ({ ...calculateTerritorialActivityKPI(res), lockYear: res[0]?.LockYear || null, averageYearRange: calculateAverageYearRange(res[0]?.LockYear || null, 5) }),
  );
}

export function useResidentPopulation() {
  return useReport(
    'territorial-activity/resident-population',
    (res) => ({ ...convertDoubleAxisBarchart(res, ['population_change', 'value'], 'year', null, null, true), lockYear: res[0]?.LockYear || null }),
  );
}

export function useGDPByIndustry() {
  return useReport(
    'territorial-activity/gdp-by-industry',
    (res) => ({ ...convertGDPData(res), lockYear: res[0]?.LockYear || null }),
  );
}

// Network Availability Hooks
export function useNetworkAvailabilityKPI() {
  return useReport(
    'network-availability/network-availability-kpi',
    (res) => ({ ...returnSingleValue(res, ['HCV_pc', 'FiftyMax_pc'], null, 100), lockYear: res?.LockYear || null }),
  );
}

export function useJourneysUnplannedEvents(framework) {
  return useReport(
    'network-availability/journeys-unplanned-events',
    (res) => ({ ...convertTornadoChart(res, ['Num_of_Incidents', 'Num_of_Journeys_Impacted'], 'ONRC'), lockYear: res[0]?.LockYear || null }),
    framework,
  );
}

export function useRoadAccessLost(framework) {
  return useReport(
    'network-availability/road-access-lost',
    (res) => ({ ...convertTornadoChart(res, ['Number_of_Road_Closures', 'Number_of_Vehicles_Affected_Annually'], 'ONRC'), lockYear: res[0]?.LockYear || null }),
    framework,
  );
}

// We need to convert the value to % by dividing the value by totalKm and then multiplying this value by 100
export function useFreightAccessibility(framework) {
  return useReport(
    'network-availability/freight-accessibility',
    (res) => ({ ...convertTornadoChart(res, ['Class_1_HCV', 'fifty_Max'], 'ONRC', 'totalKm', 100), lockYear: res[0]?.LockYear || null }),
    framework,
  );
}

export function useFreightAccessibilitySourceYear() {
  return useReport(
    'network-availability/freight-accessibility',
    (res) => res[0].Max_Class_1_Year,
  );
}

// Coinvestor Hooks
export function useInvestmentAudit() {
  return useReport('coinvestor/investment-audit', (res) => convertAudit(res, 'Procedural Audit'));
}

export function useTechnicalAudit() {
  return useReport('coinvestor/technical-audit', (res) => convertAudit(res, 'Technical Audit'));
}

export function useInvestmentAuditKPI() {
  return useReport('coinvestor/investment-audit-kpi', (res) => ({ ...calculateCoinvestInvestmentKPI(res), date: convertToFinancialYear(res.date) }));
}

export function useTechnicalAuditKPI() {
  return useReport('coinvestor/technical-audit-kpi', (res) => ({ ...calculateCoinvestTechnicalKPI(res), date: convertToFinancialYear(res.date) }));
}

// Delivery Hooks
export function useDeliveryKPI() {
  return useReport('delivery/delivery-kpi', (res) => ({ ...calculateDeliveryKPI(res), lockYear: res[0]?.LockYear || null }));
}

export function useAllTransportActivities() {
  return useReport('delivery/all-transport-activities', (res) => ({ ...convertAreaGraph(res, 4), lockYear: res[0]?.LockYear || null }));
}

export function useNewRoadsRoadImprovements() {
  return useReport('delivery/new-roads-road-improvements', (res) => ({ ...convertAreaGraph(res, 5), lockYear: res[0]?.LockYear || null }));
}

export function useRoadMaintenance() {
  return useReport('delivery/road-maintenance', (res) => ({ ...convertAreaGraph(res, 5), lockYear: res[0]?.LockYear || null }));
}

export function useCoInvestExpenditure() {
  return useReport('delivery/co-invest-expenditure', (res) => ({ ...convertAreaGraph2(res, 'FundingAuthority', ['TA', 'Waka Kotahi']), lockYear: res[0]?.LockYear || null }));
}

export function useRoadSafetyPromotion() {
  return useReport('delivery/road-safety-promotion', (res) => ({ ...convertAreaLineGraph(res, 'FundValue', 'PeerGroupValue', 1000000), lockYear: res[0]?.LockYear || null }));
}

export function useWalkingCycling() {
  return useReport('delivery/walking-cycling', (res) => ({ ...convertAreaLineGraph(res, 'FundValue', 'PeerGroupValue', 1000000), lockYear: res[0]?.LockYear || null }));
}

export function useInvestManagement() {
  return useReport('delivery/invest-management', (res) => ({ ...convertAreaLineGraph(res, 'FundValue', 'PeerGroupValue', 1000000), lockYear: res[0]?.LockYear || null }));
}

export function useRoadingAssets() {
  return useReport('delivery/roading-assets', (res) => ({ ...convertLineGraph(res, ['TotalReplacementValuation', 'DepreciatedReplacementCost'], 1000000, null, true), lockYear: res[0]?.ReportYear }));
}

export function useTotalExpenditure() {
  return useReport('delivery/total-expenditure', (res) => ({ ...convertAreaLineGraph(res, 'Ratio1000km', 'Ratio1000kmPG', 0), lockYear: res[0]?.LockYear || null }));
}

export function useMaintenanceOperations() {
  return useReport(
    'delivery/maintenance-operations',
    (res) => ({ ...convertAreaLineGraph(res, 'Ratio1000km', 'Ratio1000kmPG', 0), lockYear: res[0]?.LockYear || null }),
  );
}

export function useMaintenanceCostComparisons() {
  return useReportWithLockYear(
    'maintenance-cost-comparisons',
    (res) => res,
  );
}

// Activity Management Hooks
export function useDQAssetManagement() {
  return useReport(
    'activity-management/dqa-asset-management',
    (res) => ({ ...convertDQAssetManagement(res), lockYear: res[0]?.LockYear || null }),
  );
}

export function usePlanningQuality() {
  return useReport(
    'activity-management/planning-quality',
    (res) => ({ ...calculateColourValuesThree(res, 3.0), lockYear: res?.LockYear || null }),
  );
}

export function useCoinvestmentPlanningQuality() {
  return useReport(
    'activity-management/coinvestment-planning-quality',
    (res) => ({ ...calculateColourValuesThree(res, 3.0), lockYear: res?.LockYear || null }),
  );
}

export function useSmartBuyerSelfAssessment() {
  return useReport(
    'activity-management/smart-buyer-self-assessment',
    (res) => ({
      ...calculateColourValues(res, 70),
      lockYear: convertToFinancialYear(res.source) || res?.LockYear || null,
    }),
  );
}

export function usePlanningQualityPillars() {
  const rca = useRecoilValue(currentDatabaseSelector);
  const api = usePerformanceReportsApi();
  return useQuery(
    ['activity-management/planning-quality-pillars', rca?.id],
    async () => api.getActivityManagement('planning-quality-pillars', rca?.id, LOCKYEAR),
    {
      enabled: rca?.id !== undefined,
    },
  );
}

// Achievement Hooks
export function usePavementRehabilitation() {
  return useReport('achievements/pavement-rehabilitation', (res) => ({ ...convertGroupedBarchart(res, ['Planned', 'Actual', 'Variance']), lockYear: res[0]?.LockYear || null }));
}

export function usePavementResurfacing() {
  return useReport('achievements/pavement-resurfacing', (res) => ({ ...convertGroupedBarchart(res, ['Planned', 'Actual', 'Variance']), lockYear: res[0]?.LockYear || null }));
}

export function usePercentageNetworkRenewed() {
  return useReport('achievements/percentage-network-renewed', (res) => ({ ...convertLineGraphFilter(res, 'Percentage_of_Sealed_Kms', 'Type', ['Rehab', 'Resurface'], 100), lockYear: res[0]?.LockYear || null }));
}

export function useRideQualityRoughness() {
  return useReport('achievements/ride-quality-roughness', (res) => ({ ...convertAreaLineGraph(res, 'Value', 'PeerGroupValue', null, 100), lockYear: res[0]?.LockYear || null }));
}

export function useUnsealedRoadMetalling() {
  return useReport('achievements/unsealed-road-metalling', (res) => ({ ...convertGroupedBarchart(res, ['Forecast', 'Actual', 'Variance']), lockYear: res[0]?.LockYear || null }));
}

export function useNewAndImprovedRoads() {
  return useReport('achievements/new-and-improved-roads', (res) => ({ ...convertGroupedBarchart(res, ['Value']), lockYear: res[0]?.LockYear || null }));
}

export function useBridgeRenewalsAndNewBridges() {
  return useReport('achievements/bridge-renewals-and-new-bridges', (res) => ({ ...convertGroupedBarchart(res, ['New_Bridges', 'Bridge_Renewals']), lockYear: res[0]?.LockYear || null }));
}

export function useCulvertDrainageRenewals() {
  return useReport('achievements/drainage-renewals-culverts', (res) => ({ ...convertGroupedBarchart(res, ['Forecast', 'Achieved', 'Variance']), lockYear: res[0]?.LockYear || null }));
}

export function useKerbAndChannelDrainageRenewals() {
  return useReport('achievements/drainage-renewals-kerb-and-channel', (res) => ({ ...convertGroupedBarchart(res, ['Forecast', 'Achieved', 'Variance']), lockYear: res[0]?.LockYear || null }));
}

export function useOtherDrainageRenewals() {
  return useReport('achievements/drainage-renewals-other', (res) => ({ ...convertGroupedBarchart(res, ['Forecast', 'Achieved', 'Variance']), lockYear: res[0]?.LockYear || null }));
}

export function useRoadMaintenanceCost() {
  return useReport('achievements/road-maintenance-cost', (res) => ({ ...convertGroupedBarchart(res, ['Forecast', 'Achieved', 'Variance']), lockYear: res[0]?.LockYear || null }));
}

export function usePeakAverageRoadRoughness() {
  return useReport(
    'achievements/peak-average-road-roughness',
    (res) => ({ ...convertLineGraph(res, ['perc75_net', 'PG_perc75_net', 'perc85_net', 'PG_perc85_net', 'naasra_avg_net', 'PG_naasra_avg', 'National_Average']), lockYear: res[0]?.LockYear || null }),
  );
}

export function usePavementCondition() {
  return useReport(
    'achievements/pavement-condition',
    (res) => ({ ...convertAreaLineGraph(res, 'Value', 'PeerGroupValue', null, 100), lockYear: res[0]?.LockYear || null }),
  );
}

export function useSurfaceCondition() {
  return useReport(
    'achievements/surface-condition',
    (res) => ({ ...convertAreaLineGraph(res, 'Value', 'PeerGroupValue', null, 100), lockYear: res[0]?.LockYear || null }),
  );
}

// Safety Hooks
export function useTotalPopulation() {
  return useReport(
    'safety/total-population',
    (res) => ({ ...convertAreaLineGraph(res, 'Value', 'PeerGroupValue'), lockYear: res[0]?.LockYear || null }),
  );
}

export function useTotalVKTPersonalRisk() {
  return useReport(
    'safety/total-vkt-personal-risk',
    (res) => ({ ...convertAreaLineGraph(res, 'Value', 'PeerGroupValue'), lockYear: res[0]?.LockYear || null }),
  );
}

export function useTotalCollectiveRisk() {
  return useReport(
    'safety/total-collective-risk',
    (res) => ({ ...convertAreaLineGraph(res, 'Value', 'PeerGroupValue'), lockYear: res[0]?.LockYear || null }),
  );
}

export function useRoadMode() {
  return useReport(
    'safety/road-mode',
    (res) => ({ ...convertAreaLineGraph(res, 'Value', 'PeerGroupValue'), lockYear: res[0]?.LockYear || null }),
  );
}

export function useWalkingMode() {
  return useReport(
    'safety/walking-mode',
    (res) => ({ ...convertAreaLineGraph(res, 'Value', 'PeerGroupValue'), lockYear: res[0]?.LockYear || null }),
  );
}

export function useCyclingMode() {
  return useReport(
    'safety/cycling-mode',
    (res) => ({ ...convertAreaLineGraph(res, 'Value', 'PeerGroupValue'), lockYear: res[0]?.LockYear || null }),
  );
}

export function useCrashDistribution(framework) {
  return useReport(
    'safety/crash-distribution',
    (res) => ({ ...convertTornadoChart(res, ['perc_total_length', 'perc_total_dsi'], 'category_desc', null, 100), lockYear: res[0]?.LockYear || null }),
    framework,
  );
}
export function useStats() {
  return useReport('stats/stats');
}
