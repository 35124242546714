import { TrafficLightColours as COLOURS, stateHighwayPotholeRepairsLegend } from '@src/modules/performance/shared/constants';
import { useStateHighwayPotholeRepairsKPI } from '@src/modules/performance/context/reports-api-hooks';
import { Text } from '@chakra-ui/react';
import Widget from './Widget';
import { CircleIndicator } from '../Indicators';
import { mapValueToColourRange, roundKpiValue } from '../../shared/helpers';
import IndicatorLegend from '../IndicatorLegend';

export default function StateHighwayPotholeRepairsWidget() {
  const { data: stateHighwayPotholeRepairsData, isLoading, isError } = useStateHighwayPotholeRepairsKPI();

  const title = 'State Highway Pothole Repairs';
  const description = '% completed within 24 hours';
  const colour = mapValueToColourRange(stateHighwayPotholeRepairsData?.value, 'stateHighwayPotholeRepairs');

  // If there is an error and no data we want to hide the widget
  if (isError && (stateHighwayPotholeRepairsData?.value === undefined || !stateHighwayPotholeRepairsData?.value)) return null;

  return (
    <Widget
      link=""
      title={title}
      description={description}
      lockYear={stateHighwayPotholeRepairsData?.lockYear}
      quarter={stateHighwayPotholeRepairsData?.quarter}
      isLoading={isLoading && !stateHighwayPotholeRepairsData}
      moreInfoContent={(
        <>
          <Text fontSize="sm" mb={4}>
            The percentage of potholes on all State Highways that were repaired within 24 hours of being notified to NZTA, in the year to the reported quarter.
          </Text>
          <IndicatorLegend items={stateHighwayPotholeRepairsLegend} />
        </>
      )}
    >
      <CircleIndicator
        size={90}
        colour={COLOURS[colour]}
        value={stateHighwayPotholeRepairsData?.value !== null && stateHighwayPotholeRepairsData?.value !== undefined ? `${roundKpiValue(stateHighwayPotholeRepairsData?.value, 5, 1)}%` : null}
        isLoading={isLoading}
        padding={0}
      />
    </Widget>
  );
}
